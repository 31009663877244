<template>
  <div>
    <div id="myEchartsChaoWaAlone" style="width: 80%;height: 92vh;margin-top: 20px;z-index: 99"></div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {}
  },
  created() {
    setTimeout(this.getRateOfProgess, 100);

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
